import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import LeftNavBar from '../../../common/part/LeftNavBar';
import { AdminWrap, RowBetween } from '../../../../style/basic/commonStyle';
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { useNavigate, useParams } from 'react-router';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { apiAdmGetEasyOrderList } from '../../../../api/adm/adm_api_easyOrder';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { addTheme } from '../../../../style/addTheme';
import { ADMIN_EASY_ORDER_LIST } from '../../../../data/tableTitle';
import AdmEasyOrderListItem from '../item/AdmEasyOrderListItem';
import TableNoItem from '../../../common/item/TableNoItem';

export default function AdmEasyOrderListPage() {
    const {page} = useParams();
    const resPage = page ?? '1';
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const adminInfo = useAdminInfo();
    const navigate = useNavigate();

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0) return;
        getList();
    },[adminInfo.admin_user_id,resPage]);

    const setPage = (p:any) =>{
        navigate(`/adm/easyOrder/${p}`);
    }

    const getList = async() =>{
        const res = await apiAdmGetEasyOrderList(adminInfo.admin_jwt_token,adminInfo.admin_user_id,resPage,10,'');
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const goDetail = (id:any) =>{
        navigate(`/adm/easyOrderDetail/${id}`);
    }

    return (
        <LeftNavBar idKey={'10'}>
            <Wrap>
                <AdminPageTotalPart title='전체 견적 수' part='est_total' />
                <AdminWrap>
                    <TitleWrap>
                        <BasicText fontSize={18} mFontSize={16} fontWeight={700}>견적서 관리</BasicText>
                    </TitleWrap>
                    <TableWrap>
                        <AdminTablePart bg={addTheme.main} color='#fff' title={ADMIN_EASY_ORDER_LIST} minWidth={1100} page={page ?? 1} total={total} setPage={setPage}>
                            {list === undefined || list.length === 0 ?
                                <TableNoItem colSpan={ADMIN_EASY_ORDER_LIST.length} /> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <AdmEasyOrderListItem key={`product-${index}`} data={data} goDetail={()=>goDetail(data.est_id)} />
                                    )
                                })
                            }
                        </AdminTablePart>
                    </TableWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;

const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;
