import React, { useEffect, useState } from 'react'
import { BtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText'
import styled from 'styled-components';
import { TEL_LIST, TEL_LIST_CATE } from '../../../../data/payment';
import { numberWithComma } from '../../../../utils/format/number';
import { valueChange } from '../../../../utils/format/string';
import { apiAdmGetProductSize } from '../../../../api/adm/adm_api_product';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';

const TYPE = [{type:'1',name:'갤럭시'},{type:'2',name:'아이폰'}]

export default function AdminProductAdd1Part({data,setData,next}:any) {
    const [volumeList,setVolumeList] = useState<any>([]);
    const [selectVolList,setSelectVolList] = useState<any>([]);
    const adminInfo = useAdminInfo();

    useEffect(()=>{
        if(adminInfo.admin_jwt_token === '') return;
        getVolumeList()
    },[adminInfo.admin_jwt_token]);

    useEffect(()=>{
        setData((obj:any)=>({...obj,phone_price:selectVolList}));
    },[selectVolList])

    // 사이즈 리스트 가져오기
    const getVolumeList = async() =>{
        const res = await apiAdmGetProductSize(adminInfo.admin_jwt_token,adminInfo.admin_user_id);
        if(res.result){
            setVolumeList(res.data);
        }
    }

    const modelChange = (val:any) =>{
        setData((obj:any)=>({...obj,phone_model:val}));
    }
    const titleChange = (val:any) =>{
        setData((obj:any)=>({...obj,phone_name:val}));
    }
    const descChange = (val:any) =>{
        setData((obj:any)=>({...obj,phone_description:val}));
    }
    // 공시지원금 변경
    const priceChange = (e:any,tel_id:number) =>{
        if(data?.discount_price === undefined) return;
        const val = valueChange(e);
        const newItem = { "telco_id": tel_id, "discount_price_1": val, "discount_price_2": '0'}
        const newPrice = data.discount_price.map((item:any)=>(item.telco_id == tel_id ? newItem:item))
        setData((obj:any)=>({...obj,discount_price:newPrice}));
    }

    // 마이폰 할인 금액
    const imDiscountChange = (e:any) =>{
        const val = valueChange(e);
        setData((obj:any)=>({...obj,phone_im_discount:val}));
    }

    // 사이즈 선택
    const volChange = (e:any,vol:any,type:boolean) =>{
        if(type){
            setSelectVolList((arr:any)=>arr.filter((item:any)=>item.volume_id !== vol.volume_id))
        }else{
            setSelectVolList((arr:any)=>([...arr,{...vol,price:'0'}]))
        }
    }
    
    const volPriceChange = (e:any,vol_id:number) =>{
        const val = valueChange(e);
        const newPrice = selectVolList.map((item:any)=>(item.volume_id == vol_id ? {...item,price:val}:item));
        setSelectVolList(newPrice);
        
    }

    return (
        <Wrap>
            <ContentWrap gap={'15px'}>
                <ItemWrap pWidth={120}>
                    <BasicText>타입</BasicText>
                    <InputWrap style={{padding:0}}>
                        <SelectItem value={data?.phone_type ?? '1'} setValue={(val:any)=>setData((obj:any)=>({...obj,phone_type:val}))} none={false}>
                            {
                                TYPE.map((item:any,index:any)=>{
                                    return(
                                        <MenuItem key={`size-menu-${index}`} value={item.type}>{item.name}</MenuItem>
                                    )
                                })
                            }
                        </SelectItem>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>모델명</BasicText>
                    <InputWrap>
                        <input value={data?.phone_model ?? ''} onChange={(e:any)=>modelChange(e.target.value)} placeholder='모델명을 입력해주세요'/>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>상품제목명</BasicText>
                    <InputWrap>
                        <input value={data?.phone_name ?? ''} onChange={(e:any)=>titleChange(e.target.value)} placeholder='상품 제목을 입력해주세요'/>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText>용량</BasicText>
                    <div style={{width:'100%'}}>
                        <CheckWrap>
                            {volumeList === undefined || volumeList.length === 0 ?
                                <></> :
                                volumeList.map((item:any,index:any)=>{
                                    const chk = selectVolList.filter((chkItem:any)=>chkItem?.volume_id === item.volume_id);
                                    return(
                                        <CheckItemWrap key={`tel-${index}`}>
                                            <input type='radio' checked={chk.length > 0 ? true : false } onClick={(e:any)=>volChange(e,item,chk.length > 0 ? true : false)} readOnly></input> {item.volume_spec}{item.volume_spec<10 ? 'TB':'GB'}
                                        </CheckItemWrap>
                                    )
                                })
                            }
                        </CheckWrap>
                        <ContentWrap mt={15}>
                            {selectVolList.length !== 0 && selectVolList.map((item:any,index:any)=>{
                                return(
                                    <ItemWrap gap='15px' key={`price-${index}`}>
                                        <InputWrap max='120px'>{item.volume_spec}{item.volume_spec<10 ? 'TB':'GB'}</InputWrap>
                                        <InputWrap>
                                            <input value={numberWithComma(item.price)} onChange={(e:any)=>volPriceChange(e,item.volume_id)}></input>
                                        </InputWrap>
                                    </ItemWrap>
                                )
                            })}
                        </ContentWrap>
                    </div>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>가입통신사</BasicText>
                    <CheckWrap>
                        {
                            TEL_LIST_CATE.map((item:any,index:any)=>{
                                return(
                                    <CheckItemWrap key={`tel-${index}`}>
                                        <input type='radio' defaultChecked={true} readOnly></input> {item.name}
                                    </CheckItemWrap>
                                )
                            })
                        }
                    </CheckWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>구매방법</BasicText>
                    <CheckWrap>
                        <CheckItemWrap>
                            <input type='radio' readOnly defaultChecked={true}></input> 일시불
                        </CheckItemWrap>
                        <CheckItemWrap>
                            <input type='radio' readOnly defaultChecked={true}></input> 24개월
                        </CheckItemWrap>
                        <CheckItemWrap>
                            <input type='radio' readOnly defaultChecked={true}></input> 30개월
                        </CheckItemWrap>
                        <CheckItemWrap>
                            <input type='radio' readOnly defaultChecked={true}></input> 36개월
                        </CheckItemWrap>
                    </CheckWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>기본 텍스트</BasicText>
                    <InputWrap>
                        <input value={data?.phone_description ?? ''} onChange={(e:any)=>descChange(e.target.value)} placeholder='간단한 상품 설명을 입력해주세요.'></input>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText>공시지원금</BasicText>
                    <ContentWrap>
                        {data?.discount_price && data?.discount_price.map((item:any,index:any)=>{
                            return(
                                <ItemWrap gap='15px' key={`price-${index}`}>
                                    <InputWrap max='120px'>{TEL_LIST[item.telco_id].name}</InputWrap>
                                    <InputWrap>
                                        <input value={numberWithComma(item.discount_price_1)} onChange={(e:any)=>priceChange(e,item.telco_id)}></input>
                                    </InputWrap>
                                </ItemWrap>
                            )
                        })}
                    </ContentWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>선택약정할인</BasicText>
                    <InputWrap>
                        <BasicText>요금제 기준 25% 고정</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>마이폰할인</BasicText>
                    <InputWrap>
                        <input value={numberWithComma(data?.phone_im_discount ?? '')} onChange={(e:any)=>imDiscountChange(e)}></input>
                    </InputWrap>
                </ItemWrap>
            </ContentWrap>
            <Row style={{marginTop:'20px',gap:'15px'}}>
                <BtnItem bg={'#ad63ff'} padding='10px 25px' onClick={next}>다음</BtnItem>
            </Row>
        </Wrap>
    )
}

const Wrap = styled.div``;

const CheckWrap = styled(Row)`
    gap: 5px 15px;
    flex-wrap: wrap;
`;

const CheckItemWrap = styled.label`
    display: flex;
    align-items: center;
    gap: 4px;
`;