import React, { useEffect, useState } from 'react'
import LeftNavBar from '../../../common/part/LeftNavBar'
import styled from 'styled-components'
import { AdminWrap, BtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { apiAdmGetLink, apiAdmSetLink } from '../../../../api/adm/adm_api_link';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';

export default function AdminLinkPage() {
    const adminInfo = useAdminInfo();
    const [company,setCompany] = useState('');
    const [ceo,setCeo] = useState('');
    const [companyNumber,setCompanyNumber] = useState('');
    const [address,setAddress] = useState('');
    const [infoName,setInfoName] = useState('');
    const [infoEmail,setInfoEmail] = useState('');
    const [email,setEmail] = useState('');
    const [otherCompany,setOtherCompany] = useState('');
    const [code,setCode] = useState('');
    const [phone,setPhone] = useState('');
    const [kakao,setKakao] = useState('');
    const [reviewLink,setReviewLink] = useState('');

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0) return;
        getInfo();
    },[adminInfo.admin_user_id])

    const getInfo = async() =>{
        const res = await apiAdmGetLink(adminInfo.admin_jwt_token,adminInfo.admin_user_id);
        if(res.result){
            setCompany(res.data?.config_company_name);
            setCeo(res.data?.config_company_repre_name);
            setCompanyNumber(res.data?.config_business_number);
            setAddress(res.data?.config_company_address);
            setInfoName(res.data?.config_private_info_name);
            setInfoEmail(res.data?.config_private_info_email);
            setEmail(res.data?.config_company_email);
            setOtherCompany(res.data?.config_partner_company);
            setCode(res.data?.config_online_prev_approve_code);
            setPhone(res.data?.config_company_call_number);
            setKakao(res.data?.config_social_kakao_link);
            setReviewLink(res.data?.config_review_link);
        }
    }

    const updateAction = async() =>{
        if(adminInfo.admin_user_id === 0) return;
        const res = await apiAdmSetLink(adminInfo.admin_jwt_token,adminInfo.admin_user_id,company,ceo,companyNumber,address,infoName,infoEmail,email,otherCompany,code,phone,kakao,reviewLink);
        if(res.result){
            alert('수정되었습니다.');
        }else{
            alert(res.error);
        }
    }

    return (
        <LeftNavBar idKey={'7'}>
            <Wrap>
                <AdminWrap marginTop={0}>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>링크 관리</BasicText>
                    <InnerWrap>
                        <ContentWrap>
                            <ItemWrap>
                                <BasicText>회사명</BasicText>
                                <InputWrap>
                                    <input value={company} onChange={(e:any)=>setCompany(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>대표자</BasicText>
                                <InputWrap>
                                    <input value={ceo} onChange={(e:any)=>setCeo(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>사업자번호</BasicText>
                                <InputWrap>
                                    <input value={companyNumber} onChange={(e:any)=>setCompanyNumber(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>회사 주소</BasicText>
                                <InputWrap>
                                    <input value={address} onChange={(e:any)=>setAddress(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>개인정보관리 책임자</BasicText>
                                <InputWrap>
                                    <input value={infoName} onChange={(e:any)=>setInfoName(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>개인정보관리 책임자 이메일</BasicText>
                                <InputWrap>
                                    <input value={infoEmail} onChange={(e:any)=>setInfoEmail(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>이메일</BasicText>
                                <InputWrap>
                                    <input value={email} onChange={(e:any)=>setEmail(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>판매제휴업체</BasicText>
                                <InputWrap>
                                    <input value={otherCompany} onChange={(e:any)=>setOtherCompany(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>온라인 사전승낙서 코드</BasicText>
                                <InputWrap>
                                    <input value={code} onChange={(e:any)=>setCode(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>전화번호</BasicText>
                                <InputWrap>
                                    <input value={phone} onChange={(e:any)=>setPhone(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>카톡링크</BasicText>
                                <InputWrap>
                                    <input value={kakao} onChange={(e:any)=>setKakao(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText>리뷰링크</BasicText>
                                <InputWrap>
                                    <input value={reviewLink} onChange={(e:any)=>setReviewLink(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                        </ContentWrap>
                        <Row style={{marginTop:'50px'}}>
                            <BtnItem bg={addTheme.main} padding='14px 28px' onClick={updateAction}>링크 수정</BtnItem>
                        </Row>
                    </InnerWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 580px;
    margin-top: 30px;
`;