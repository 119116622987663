import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import ListPart from '../part/ListPart';
import { apiGetPhList } from '../../../api/api_phone';
import { useLocation, useNavigate } from 'react-router';
import SliderBannerPart from '../part/SliderBannerPart';
import QnaContactItem from '../../Qna/item/QnaContactItem';
import TextPart from '../part/TextPart';
import HomeReviewPart from '../part/HomeReviewPart';
import PhoneUserListPart from '../part/PhoneUserListPart';
import { addTheme } from '../../../style/addTheme';
import { useGlobalCompany } from '../../../utils/custom-hooks/common/useGlobalCompany';

export default function HomePage() {
    const location = useLocation();
    const company = useGlobalCompany();
    const gRef = useRef<any>(null);
    const iRef = useRef<any>(null);
    const [listG,setListG] = useState([]);
    const [listI,setListI] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        if(location.hash === '') {
            const topTime = setTimeout(()=>{
                window.scrollTo(0,0);
            }, 180)
            return () => clearTimeout(topTime)
        };
        const timer = setTimeout(() => {
            if(location.hash.includes('galaxy')){
                gClick();
            }
            if(location.hash.includes('iphone')){
                iClick();
            }
        }, 180);
        return () => clearTimeout(timer);
    },[location.hash])

    useEffect(()=>{
        getList();
    },[])

    const gClick = () =>{
        if(gRef === null) return;
        gRef.current.scrollIntoView({ behavior: 'auto',block: 'start',inline: 'nearest' });
    }

    const iClick = () =>{
        if(iRef === null) return;
        iRef.current.scrollIntoView({ behavior: 'auto',block: 'start',inline: 'nearest' });
    }

    const getList = async() =>{
        const res = await apiGetPhList(1,150);
        if(res.result){
            const g = res.data.filter((data:any)=>data.phone_type === '1');
            const i = res.data.filter((data:any)=>data.phone_type === '2');
            setListG(g);
            setListI(i);
        }
    }

    // 지금 전화하기
    const phoneClick = () =>{
        if(company.link['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + company.link['config_company_call_number'];
    }

    // 빠른 견적하기
    const goEasyOrder = () =>{
        navigate('/easyOrder');
    }
    return (
        <LayoutPage gClick={gClick} iClick={iClick}>
            <Wrap>
                <SliderBannerPart />
                <PhoneUserListPart />
                <TextPart />
                <HomeReviewPart />
                <MaxWidthWrap>
                    <div ref={gRef}>
                        <ListPart title='갤럭시' list={listG}/>
                    </div>
                </MaxWidthWrap>
                <MaxWidthWrap>
                    <div ref={iRef}>
                        <ListPart title='아이폰' list={listI}/>
                    </div>
                </MaxWidthWrap>
                <QnaContactItem />
                <CallWrap>
                    <CallItem onClick={phoneClick} bg='#000000'>지금 전화하기</CallItem>
                    <CallItem onClick={goEasyOrder} bg='#003FD8'>빠른견적받기</CallItem>
                </CallWrap>
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div`
    position: relative;
`;

const CallWrap = styled(Center)`
    position: sticky;
    height: 64px;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 11;
    gap: 40px;
    @media ${addTheme.media.m}{
        padding: 0 15px;
        gap: 15px;
        height: 52px;
    }
    @media ${addTheme.media.s}{
        padding: 0;
        gap: 0;
        bottom: 0;
        &>div{
            border-radius: 0;
        }
    }
`;

const CallItem = styled(Center)<{bg:string}>`
    background-color: ${({bg}) => bg ?? addTheme.main};
    color: #fff;
    width: 280px;
    height: 100%;
    border-radius: 30px;
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
    @media ${addTheme.media.m}{
        font-size: 18px;
    }
`;
