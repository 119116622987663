import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { ContentWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { calculateMonthlyPayment, numberWithComma } from '../../../utils/format/number';

export default function RegisterPayPart({info,data,cardDiscount,monthResult,monthPrice}:any) {
    const infoResultCal = info === undefined ? undefined : info?.order_device_price - info?.order_im_discount_price - (cardDiscount ?? 0) - (info?.discount_type === '1' ? info?.order_basic_discount_price : 0); 
    const infoResult = infoResultCal === undefined ? undefined : infoResultCal < 0 ? 0 : infoResultCal;
    const infoMonthPrice = infoResult === undefined ? undefined : Math.floor(infoResult / (info?.payment_month === 0 ? 1 : info?.payment_month));
    const infoMonthTotalPay = infoResult === undefined ? undefined : info?.payment_month === 0 ? 0 : calculateMonthlyPayment(infoResult,(info?.payment_month === 0 ? 1 : info?.payment_month));
    const infoPlanPrice = info === undefined ? undefined : info?.discount_type === '1' ? info?.plan_name?.plan_price : info?.plan_name?.plan_price - info?.discount2;
    
    return (
        <Wrap>
            <BasicText fontSize={20} fontWeight={700}>가격 정보</BasicText>
            <ContentWrap mt={20} gap={10}>
                <ItemWrap>
                    <BasicText fontSize={18} color={addTheme.main} fontWeight={700}>{(data?.buyType === 0 || info?.payment_month === 0) ? '일시불' : '월 할부금'}</BasicText>
                    <BasicText fontSize={18} color={addTheme.main} fontWeight={700}>{numberWithComma(monthPrice?.monthPay ?? infoMonthPrice)}원</BasicText>
                </ItemWrap>
                <ItemWrap>
                    <BasicText fontSize={17}>출고가</BasicText>
                    <BasicText fontSize={17}>{numberWithComma(data?.price ?? info?.order_device_price)}원</BasicText>
                </ItemWrap>
                {(data?.discount_type ?? info?.discount_type) === '1' &&
                    <ItemWrap>
                        <BasicText fontSize={17}>공시지원금</BasicText>
                        <BasicText fontSize={17} color={addTheme.main}>-{numberWithComma(data?.discount1 ?? info?.order_basic_discount_price)}원</BasicText>
                    </ItemWrap>
                }
                {(data?.imDiscount ?? info?.order_im_discount_price ?? 0) !== 0 &&
                    <ItemWrap>
                        <BasicText fontSize={17}>마이폰할인</BasicText>
                        <BasicText fontSize={17} color={addTheme.main}>-{numberWithComma(data?.imDiscount ?? info?.order_im_discount_price)}원</BasicText>
                    </ItemWrap>
                }
                {(data?.cardDiscount ?? cardDiscount ?? 0) !== 0  &&
                    <ItemWrap>
                        <BasicText fontSize={17}>제휴 카드 할인</BasicText>
                        <BasicText fontSize={17} color={addTheme.main}>-{numberWithComma(data?.cardDiscount ?? cardDiscount)}원</BasicText>
                    </ItemWrap>
                }
                <ItemWrap>
                    <BasicText fontSize={17}>할부원금</BasicText>
                    <BasicText fontSize={17} color={addTheme.main}>{numberWithComma(data?.result ?? infoResult)}원</BasicText>
                </ItemWrap>
            </ContentWrap>
            <ContentWrap mt={15} gap={10}>
                <ItemWrap>
                    <BasicText fontSize={18} color={addTheme.main} fontWeight={700}>월 통신 요금</BasicText>
                    <BasicText fontSize={18} color={addTheme.main} fontWeight={700}>{numberWithComma(monthPrice?.planPrice ?? info?.planPrice ?? 0)}원</BasicText>
                </ItemWrap>
                <ItemWrap>
                    <BasicText fontSize={17}>{data?.plan?.plan_name ?? info?.plan_name.plan_name}</BasicText>
                    <BasicText fontSize={17}>{numberWithComma(data?.plan?.plan_price ?? info?.plan_name.plan_price)}원</BasicText>
                </ItemWrap>
                {(data?.discount_type ?? info?.discount_type) === '2' &&
                    <ItemWrap>
                        <BasicText fontSize={17}>선택약정할인(25%)</BasicText>
                        <BasicText fontSize={17} color={addTheme.main}>-{numberWithComma(data?.discount2 ?? info?.discount2)}원</BasicText>
                    </ItemWrap>
                }
                
            </ContentWrap>
            <ResWrap>
                <ItemWrap>
                    <Row style={{width:'auto',flexDirection:'column',alignItems:'start'}}>
                        <BasicText color='#000' fontSize={16}>* 부가세(Vat) 포함 금액</BasicText>
                        {data?.buyType === 0 ?
                            <BasicText color='#000' fontSize={16}>* 일시불 구매시 할부이자가 없습니다.</BasicText> :
                            <BasicText color='#000' fontSize={16}>* 할부이자 할부원금 기준 5.9% {numberWithComma(infoMonthTotalPay === undefined ? (monthPrice?.monthTotalPay ?? 0)-(monthPrice?.monthPay ?? 0) : infoMonthTotalPay - (infoMonthPrice ?? 0))}원 포함</BasicText>
                        }
                    </Row>
                    <Row style={{width:'auto',flexDirection:'column',alignItems:'end'}}>
                        <BasicText color='#000' fontSize={18}>최종 월 납부 금액</BasicText>
                        <BasicText color={addTheme.main} fontSize={28} mFontSize={24} fontWeight={700} ls={2}>{numberWithComma(monthResult ?? (infoMonthTotalPay ?? 0) + (infoPlanPrice ?? 0))}원</BasicText>
                    </Row>
                </ItemWrap>
            </ResWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ItemWrap = styled(RowBetween)`
    flex-wrap: wrap;
    gap: 20px 0;
`;
const ResWrap = styled.div`
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid #dfdfdf;
`;