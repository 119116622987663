import styled, { css } from 'styled-components';
import { addTheme } from '../addTheme';

export const MaxWidthWrap = styled.div<{maxWidth?:string,margin?:string, padding?:string,height?:string}>`
    width:100%;
    height: ${props => props.height ?? 'auto'};
    max-width: ${props=>props.maxWidth ?? addTheme.maxWidth};
    margin: ${props=>props.margin ?? '0 auto'};
    padding: ${props=>props.padding ?? 0};
    @media ${addTheme.media.l}{
        padding: ${props=>props.padding ?? addTheme.padding};
    }
`;

export const ColorItem = styled.div<{size?:number,color:string}>`
    background-color: ${({color}) => color ?? '#fff'};
    width: ${({size}) => size ?? 6}px;
    height: ${({size}) => size ?? 6}px;
`;

export const AdminWrap = styled.div<{marginTop?:number}>`
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    margin-top: ${({marginTop}) => marginTop ?? 20}px;
    @media ${addTheme.media.s}{
        padding: 10px;
    }
`;


export const TextItem = styled.div<{color?:string, fontSize?:number, mFontSize?:number, marginTop?:number, marginRight?:number, line?:number, fw?:number,textAlign?:'center'|'left'|'right',w?:string}>`
    width: ${props => props.w ?? '100%'};
    font-size: ${props => props.fontSize ?? 16}px;
    color: ${props => props.color ?? '#fff'};
    margin-top: ${props => props.marginTop ?? 0}px;
    margin-right: ${props => props.marginRight ?? 0}px;
    font-weight: ${props => props.fw ?? 400};
    text-align: ${props => props.textAlign ?? 'left'};
    line-height: ${props=> props.fontSize === undefined ? 19 : props.fontSize+3}px;
    @media ${addTheme.media.m}{
        font-size: ${props=> props.mFontSize ?? (props.fontSize ?? 16)}px;
        line-height: ${props=>props.mFontSize === undefined ? props.fontSize === undefined ? 19 : props.fontSize+3 : props.mFontSize+3}px;
    }
    ${props => props.line !== undefined && css<{line?:number, fontSize?:number,mFontSize?:number}>`
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${props => props.line ?? 1};
        -webkit-box-orient: vertical;
        height: ${props=> (props.line ?? 1) * (props.fontSize === undefined ? 19 : props.fontSize+3)}px;
        @media ${addTheme.media.m}{
            height: ${props=>(props.line ?? 1) * (props.mFontSize === undefined ? props.fontSize === undefined ? 19 : props.fontSize+3 : props.mFontSize+3)}px;
        }
    `}
`;

export const Center = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CenterWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const RowBetween = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const Left = styled.div`
    display: flex;
    align-items: left;
    justify-content: center;
`;

export const PaddingWrap = styled.div<{padding?: number}>`
    padding: ${props => props.padding ?? 0}px;
`;

export const FullWrap = styled.div<{padding?: number}>`
    width: 100%;
    height: 100%;
    padding: ${props => props.padding ?? 0}px;
`;

export const JustifyWrap = styled.div<{height?: string}>`
    justify-content: space-between;
    flex-direction: column;
    height: ${props => props.height ?? undefined};
`;

export const ItemDiv = styled.div<{width?: number, center?: boolean, height?: number}>`
    height: ${props => props.height ?? 100}%;
    max-height: ${props => props.height ?? 100}%;
    width: ${props => props.width ?? 20}%;
    display: flex;
    justify-content: ${props => props.center ? 'center' : 'flex-start'};
    align-items: center;
`;

export const ItemDivBetween = styled.div<{width?: number, center?: boolean, height?: number}>`
    height: ${props => props.height ?? 100}%;
    max-height: ${props => props.height ?? 100}%;
    width: ${props => props.width ?? 20}%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


export const IconImage = styled.img<{size?: number}>`
    width: ${props => props.size ?? 50}px;
    height: ${props => props.size ?? 50}px;
`;

export const WidthWrap = styled.div<{width?: number, align?: string}>`
    width: ${props => props.width}%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: ${props => props.align ?? 'center'};
    flex-direction: column;
`;

export const BtnItem = styled(Center)<{bg:string,padding?:string,color?:string,round?:number}>`
    cursor: pointer;
    padding: ${({padding}) => padding ?? '10px 18px'};
    background-color: ${({bg}) => bg ?? '#fff'};
    color: ${({color}) => color ?? '#fff'};
    border-radius: ${({round}) => round ?? 6}px;
`;

export const ButtonItem = styled.button<{bg:string,padding?:string,color?:string,round?:number}>`
    cursor: pointer;
    padding: ${({padding}) => padding ?? '10px 18px'};
    background-color: ${({bg}) => bg ?? '#fff'};
    color: ${({color}) => color ?? '#fff'};
    border-radius: ${({round}) => round ?? 6}px;
`;

export const ContentWrap = styled(Row)<{mt?:number,gap?:any}>`
    margin-top: ${({mt}) => mt ?? 0}px;
    flex-direction: column;
    align-items: stretch;
    gap: ${({gap}) => gap === undefined ? '8px' : typeof gap === 'string' ? gap : `${gap}px`};
`;

export const ItemWrap = styled(Row)<{pWidth?:number,align?:string,gap?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    gap: ${({gap}) => gap ?? 0};
    &>p{
        width: ${({pWidth}) => pWidth ?? 185}px;
        flex-shrink: 0;
    }
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
    @media ${addTheme.media.sx}{
        &>p{
            width: 100%;
        }
    }
`;

export const InputWrap = styled.div<{max?:string,padding?:string}>`
    border: 1px solid #d4d4d4;
    padding: ${({padding}) => padding ?? '8px 12px'};
    border-radius: 8px;
    width: 100%;
    max-width: ${({max}) => max ?? 'auto'};
    & input{
        width: 100%;
    }
    & textarea{
        width: 100%;
    }
`;

export const SectionWrap = styled.div<{padding?:string, bg?:string, margin?:string}>`
    padding: ${({padding}) => padding ?? '70px 0'};
    background-color: ${({bg}) => bg ?? '#fff'};
    margin: ${({margin}) => margin ?? 0};
`;