import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components'
import LeftNavBar from '../../../common/part/LeftNavBar';
import { AdminWrap, BtnItem, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { apiAdmGetEasyOrderDetail, apiAdmSetEasyOrderStatus } from '../../../../api/adm/adm_api_easyOrder';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import AdmEasyOrderDetailInfoPart from '../part/AdmEasyOrderDetailInfoPart';
import { addTheme } from '../../../../style/addTheme';

const INFO_INIT = {
    "est_id": 0,
    "est_code": "",
    "est_user_name": "",
    "est_phone_number": "",
    "est_using_telco_id": 1,
    "est_want_telco_id": 1,
    "est_want_model": "",
    "est_timestamp": "",
    "est_status": "0",
    "est_memo": "",
    "using_telco": "",
    "want_telco": ""
}
export default function AdmEasyOrderDetailPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const adminInfo = useAdminInfo();
    const [info,setInfo] = useState(INFO_INIT);

    useEffect(() =>{
        if(id === undefined || id === '0'){
            alert('잘못된 접근입니다.');
            navigate(-1);
        }
    },[id]);

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0) return
        if(id === undefined) return;
        getInfo();
    },[adminInfo.admin_user_id,id])

    const getInfo = async() =>{
        const res = await apiAdmGetEasyOrderDetail(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
        if(res.result){
            setInfo(res.data[0]);
        }
    }

    // 변경 저장
    const setInfoData = async() =>{
        const res = await apiAdmSetEasyOrderStatus(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id,info.est_status);
        if(res.result){
            alert('변경한 내용이 저장되었습니다.');
        }else{
            alert(res.error);
        }
    }

    return (
        <LeftNavBar idKey='10'>
            <AdminWrap marginTop={0}>
                <TitleWrap>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>견적서 상세보기</BasicText>
                </TitleWrap>
                <ContentWrap>
                    <InnerContentWrap>
                        <AdmEasyOrderDetailInfoPart info={info} setInfo={setInfo} />
                        <Row style={{marginTop:'60px'}}>
                            <BtnItem onClick={setInfoData} bg='#18A40E'>변경사항 저장</BtnItem>
                        </Row>
                    </InnerContentWrap>
                    <InnerContentWrap />
                </ContentWrap>
            </AdminWrap>
        </LeftNavBar>
    )
}

const TitleWrap = styled(Row)`
    gap: 20px;
    flex-wrap: wrap;
`;

const ContentWrap = styled(Row)`
    margin-top: 10px;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
    }
`;
const InnerContentWrap = styled.div`
    width: 50%;
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;