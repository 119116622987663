import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { BtnItem, Center } from '../../../../style/basic/commonStyle'
import { ADMIN_EASY_ORDER_LIST } from '../../../../data/tableTitle'
import { momentFormat } from '../../../../utils/format/time'

const STATUS:any = {
    '0': '대기중',
    '1': '상담완료',
}

const USE_TEL:any = {
    '1': 'SKT',
    '2': 'KT',
    '3': 'LGU+',
    '4': '알뜰폰'
}
export default function AdmEasyOrderListItem({data,goDetail}:any) {
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADMIN_EASY_ORDER_LIST[0].align}>{data.est_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_EASY_ORDER_LIST[1].align}>{data.est_user_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_EASY_ORDER_LIST[2].align}>{USE_TEL[data?.est_using_telco_id ?? '1']}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_EASY_ORDER_LIST[3].align}>{USE_TEL[data?.est_want_telco_id ?? '1']}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_EASY_ORDER_LIST[4].align}>{data.est_want_model}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_EASY_ORDER_LIST[4].align}>{data.est_phone_number}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_EASY_ORDER_LIST[5].align}>{momentFormat('YYYY-MM-DD hh:mm',data.est_timestamp) }</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_EASY_ORDER_LIST[6].align}>{STATUS[data?.est_status ?? '0']}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_EASY_ORDER_LIST[7].align}>
                <Center style={{gap:'8px'}}>
                    <BtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={goDetail}>상세</BtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}
