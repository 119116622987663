import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { SwiperSlide } from 'swiper/react';
import SwiperPart from './SwiperPart';
import { apiGetBannerList } from '../../../api/api_home';
import { momentFormat } from '../../../utils/format/time';

export default function PopupPart() {
    const ref = useRef<any>(null);
    const [list,setList] = useState<any>([]);
    const [popupState,setPopupState] = useState(false);

    useEffect(()=>{
        if(list.length === 0) return;
        const popupDate = localStorage.getItem('popupDate');
        const time = new Date().getTime();
        const resTime = momentFormat('YYYYMMDD',time);
        if(popupDate === null){
            setPopupState(true);
            localStorage.setItem('popupDate', resTime);
        }else{
            if(popupDate === resTime){
                setPopupState(false);
            }else{
                setPopupState(true);
                localStorage.setItem('popupDate', resTime);
            }
        }
    },[list])

    const close = () =>{
        setPopupState(false);
    }

    const getList = async() =>{
        const res = await apiGetBannerList(1,10,'2');
        if(res.result){
            setList(res.data);
            if(res.data.length === 0){
                close();
            }
        }
    }

    useEffect(()=>{
        getList();
    },[]);

    const clickAction = (url:string) =>{
        if(url === undefined || url === '') return;
        window.open(url);
    }
    return (
        <Dialog
            ref={ref}
            open={popupState} 
            // onClose={close}
            sx={{
                '.MuiPaper-root':{margin:'8px', maxWidth:'420px',width:'100%',borderRadius: 0,}
            }}
        >
            <DialogContent dividers={true} sx={{padding: 0,borderTop: 'none', borderBottom: 'none'}}>
                <Wrap>
                    <SwiperPart space={0} pagination={true} view={1}>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            list.map((data:any,index:any)=>{
                                return(
                                    <SwiperSlide key={`popup-${index}`}>
                                        <ImgWrap onClick={()=>clickAction(data.banner_link_url)}>
                                            <img src={data.banner_img_mo}></img>
                                        </ImgWrap>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </SwiperPart>
                </Wrap>
            </DialogContent>
            <DialogActions sx={{padding:0}}>
                <CloseWrap onClick={close}>
                    <BasicText color='#fff' fontSize={18}>닫기</BasicText>
                </CloseWrap>
            </DialogActions>
        </Dialog>
    )
}

const Wrap = styled.div`
    & .swiper-pagination-bullet{
        background: #fff;
    }
    & .swiper {
        background-color: #000;
    }
    & .swiper-wrapper{
        align-items: center;
    }
`;
const CloseWrap= styled(Center)`
    width: 100%;
    height: 52px;
    background-color: ${addTheme.main};
    cursor: pointer;
`;

const ImgWrap = styled.div`
    display: flex;
    & img{
        width: 100%;
    }
`;