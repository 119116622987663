
export const TAB_MENU = [
    {id: '2', name: '주문 관리', router: '/adm/order/1', },
    {id: '10', name: '견적서 관리', router: '/adm/easyOrder/1', },
    {id: '3', name: '후기 관리', router: '/adm/review/1',},
    {id: '4', name: '문의 관리', router: '/adm/inquiry/1', },
    {id: '5', name: 'FAQ 관리', router: '/adm/faq', },
    {id: '6', name: '상품 관리', router: '/adm/product/1', },
    {id: '8', name: '배너 관리', router: '/adm/banner/1', },
    {id: '9', name: '팝업 관리', router: '/adm/popup/1', },
    {id: '7', name: '링크 관리', router: '/adm/link', },
];