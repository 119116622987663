import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

export default function TextPart() {
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <TextWrap>
                        <BasicText fontSize={40} mFontSize={28} spColor={addTheme.main} fontWeight={700}>고객님의 <span>휴대폰 구매</span>를</BasicText>
                        <BasicText fontSize={40} mFontSize={28} spColor={addTheme.main} fontWeight={700}>나의 <span>휴대폰 구매</span>할때처럼</BasicText>
                        <BasicText fontSize={40} mFontSize={28} spColor={addTheme.main} fontWeight={700}><span>'마이폰'</span>은 다릅니다</BasicText>
                    </TextWrap>
                    <ImgWrap>
                        <img src='/img/asset/home_img.png'></img>
                    </ImgWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #F4F4F4;
    padding: 58px 0;
`;
const InnerWrap = styled(Center)`
    align-items: start;
    gap: 80px;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        gap: 20px;
        &>div{
            width: 100%;
        }
    }
`;
const TextWrap = styled.div`
    flex-shrink: 0;
    margin-top: 50px;
    @media ${addTheme.media.m}{
        margin-top: 20px;
    }
`;
const ImgWrap = styled.div`
    & img{
        width: 100%;
    }
`;