import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { FaAngleRight } from "react-icons/fa6";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { apiGetReview } from '../../../api/api_review';
import ReviewItem from '../../review/item/ReviewItem';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';
import { useGlobalCompany } from '../../../utils/custom-hooks/common/useGlobalCompany';

export default function HomeReviewPart() {
    const [list,setList] = useState([]);
    const mediaQuery = useMediaQuery(addTheme.media.m);
    const mediaQuery_s = useMediaQuery(addTheme.media.s)
    const company = useGlobalCompany();
    
    useEffect(()=>{
        getList();
    },[])
    const getList = async() =>{
        const res = await apiGetReview(1,15);
        if(res.result){
            setList(res.data);
        }
    }
    const moreClick = () =>{
        const link = company.link['config_review_link']
        if(link === undefined) return;
        window.open(link);
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <BasicText fontSize={34} mFontSize={28} color='#fff' align='center' spColor='#E2E2E2'>대한민국 통신비 0원 프로젝트{mediaQuery_s && <br/>}"마이폰"<span>에서</span></BasicText>
                    <BasicText fontSize={28} mFontSize={20} color='#E2E2E2' align='center' marginTop={1}>가입하신 고객님들의 실제 후기입니다</BasicText>
                    <ListWrap>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            <Swiper
                                modules={[Autoplay]}
                                slidesPerView={mediaQuery ? 1 : 3}
                                spaceBetween={30}
                                loop={true}
                                autoplay={{
                                    delay: 3000, 
                                }}
                            >
                                {list.map((data:any,index:any)=>{
                                    return(
                                        <SwiperSlide key={`review-${index}`}>
                                            <ReviewItem data={data} />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        }
                    </ListWrap>
                    <Center>
                        <BtnWrap onClick={moreClick}>
                            100% "찐"후기 더 많이 보기
                            <FaAngleRight color='#000' />
                        </BtnWrap>
                    </Center>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #121619;
    padding: 60px 0;
`;
const InnerWrap = styled.div`
    & span{
        font-size: 28px;
        font-weight: 400;
    }
    @media ${addTheme.media.m}{
        & span{
            font-size: 20px;
        }
    }
`;
const ListWrap = styled.div`
    margin-top: 30px;
`;
const BtnWrap = styled(Row)`
    margin-top: 55px;
    width: auto;
    background-color: #FFD700;
    height: 64px;
    padding: 0 28px;
    border-radius: 70px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    @media ${addTheme.media.s}{
        font-size: 18px;
    }
`;