import { axiosConfigInstance } from "../config";

// 관리자 빠른 견적서 리스트
export const apiAdmGetEasyOrderList = async(token:string,admin_id:any,page:any,count:any,search_query:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/estimate',token).post('',{
            method: 'getAdminEstimateList',
            params: {
                admin_id,
                page,
                count,
                search_query,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 견적서 디테일
export const apiAdmGetEasyOrderDetail = async(token:string,admin_id:any,est_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/estimate',token).post('',{
            method: 'getAdminEstimateDetailInfo',
            params: {
                admin_id,
                est_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 견적서 상태 변경
export const apiAdmSetEasyOrderStatus = async(token:string,admin_id:any,est_id:any,est_status:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/estimate',token).post('',{
            method: 'adminModifyEstimateInfo',
            params:{
                admin_id,est_id,est_status
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}