import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { addTheme } from '../../../style/addTheme';
import { apiGetBannerList } from '../../../api/api_home';
import { Row } from '../../../style/basic/commonStyle';

export default function SliderBannerPart() {
    const [list,setList] = useState<any>([]);
    const [drag,setDrag] = useState(false);

    useEffect(()=>{
        getList();
    },[])
    const getList = async() =>{
        const res = await apiGetBannerList(1,50,'1');
        if(res.result){
            setList(res.data);
        }
    }
    
    const handleBeforeChange = useCallback(() => {
        if(setDrag){
            setDrag(true);
        }
    }, []); 
    const handleAfterChange = useCallback((i: number) => {
        if(setDrag){
            setDrag(false);
        }
    }, []);

    const setting = {
        infinite: true,
        speed:1000,
        touchThreshold : 100,
        autoplaySpeed: 5000,
        autoplay:true,
        arrows:false,
        pauseOnHover : true,
        beforeChange: handleBeforeChange,    
        afterChange: handleAfterChange,
        dots: true,
    }

    const itemClick = (link:any) =>{
        if(!drag){
            window.open(link);
        }
    }

    return (
        <Wrap>
            {list === undefined || list.length === 0 ?
                <></> :
                list.length === 1 ?
                    <ImgWrap>
                        <img className='banner-pc-img' src={list[0].banner_img_web} />
                        <img className='banner-m-img' src={list[0].banner_img_mo} />
                    </ImgWrap> :
                    <Slider {...setting}>
                        {list.map((data:any,index:any)=>{
                            return(
                                <ImgWrap key={`banner-${index}`} onClick={()=>itemClick(data.banner_link_url)}>
                                    <img className='banner-pc-img' src={data.banner_img_web} />
                                    <img className='banner-m-img' src={data.banner_img_mo} />
                                </ImgWrap>
                            )
                        })}
                    </Slider>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    & .slick-track{
        div:focus-visible{
            outline: none;
        }
    }
    & .slick-dots{
        bottom: 20px;
    }
    & .slick-dots li{
        height: 6px;
        width: 27px;
        border-radius: 2px;
        overflow: hidden;
        transition: 0.3s;
        margin: 0 3px;
        @media ${addTheme.media.s}{
            width: 20px;
        }
    }
    & .slick-dots li.slick-active{
        width: 60px;
        @media ${addTheme.media.s}{
            width: 45px;
        }
    }
    & .slick-dots li button:before{
        content: '';
        background-color: #fff;
        width: 100%;
        height: 100%;
    }
    & .slick-dots li.slick-active button:before{
        opacity: 1;
        
    }
    & .slick-list{
        font-size: 0;
    }
`;
const ImgWrap = styled(Row)`
    width: 100%;
    & img{
        width: 100%;
        :focus-visible{
            outline: none !important;
        }
    }
    & .banner-m-img{
        display: none;
    }

    @media ${addTheme.media.s}{
        .banner-m-img{
            display: block;
        }
        .banner-pc-img{
            display: none;
        }
    }
`;