import React from 'react'
import LayoutPage from '../../common/page/LayoutPage'
import styled from 'styled-components'
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { useGlobalCompany } from '../../../utils/custom-hooks/common/useGlobalCompany';
import { useNavigate } from 'react-router';

export default function EasyOrderDonePage() {
    const company = useGlobalCompany();
    const navigate = useNavigate();
    // 지금 전화하기
    const phoneClick = () =>{
        if(company.link['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + company.link['config_company_call_number'];
    }
    
    // 가입 후기기
    const goReview = () =>{
        if(company.link['config_review_link'] === undefined) return;
        window.open(company.link['config_review_link']);
    }
    return (
        <LayoutPage>
            <MaxWidthWrap>
                <Wrap>
                    <TitleWrap>
                        <div>
                            <BasicText fontSize={38} mFontSize={24} fontWeight={700} align='center'>전국민 통신비 0원 프로젝트 마이폰<br/>상담신청이 완료되었습니다</BasicText>
                            <BasicText fontSize={28} mFontSize={18} align='center' marginTop={3}>신청서 확인 후 30분내로 연락드립니다.</BasicText>
                        </div>
                    </TitleWrap>
                    <CallWrap>
                        <CallItem onClick={phoneClick} bg='#000000'>지금 전화하기</CallItem>
                        <CallItem onClick={goReview} bg='#003FD8'>가입 후기 보러가기</CallItem>
                    </CallWrap>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(Center)`
    margin-top: 100px;
`;

const CallWrap = styled(Center)`
    gap: 40px;
    margin-top: 50px;
    height: 64px;
    @media ${addTheme.media.m}{
        gap: 15px;
        height: 52px;
    }
`;

const CallItem = styled(Center)<{bg:string}>`
    background-color: ${({bg}) => bg ?? addTheme.main};
    color: #fff;
    width: 280px;
    height: 100%;
    border-radius: 30px;
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
    @media ${addTheme.media.m}{
        font-size: 18px;
    }
`;
