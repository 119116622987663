import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import SelectPart from '../part/SelectPart';
import ResultPart from '../part/ResultPart';
import ContentPart from '../part/ContentPart';
import { USE_CARRIER } from '../../../data/data';
import { TEL_LIST } from '../../../data/payment';
import { useNavigate, useParams } from 'react-router';
import { apiGetDiscount, apiGetPhDetail } from '../../../api/api_phone';
import { setGlobalRegister } from '../../../store/common/globalRegister';
import { useDispatch } from 'react-redux';

export default function DetailPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [info,setInfo] = useState<any>();
    const [size,setSize] = useState<any>();
    const [price,setPrice] = useState('0');
    const [color,setColor] = useState<any>();
    const [use,setUse] = useState(USE_CARRIER[0]);
    const [nowUse,setNowUse] = useState<any>();
    const [paymentList,setPaymentList] = useState([]);
    const [payment,setPayment] = useState<any>();
    const [discountType,setDiscountType] = useState('1');
    const [discount,setDiscount] = useState<any>();

    const [buyType,setBuyType] = useState('24');
    const [cardList,setCardList] = useState([]);
    const [cardType,setCardType] = useState<any>();
    // const [sellItem,setSellItem] = useState();
    const dispatch = useDispatch();
    
    useEffect(()=>{
        if(info === undefined) return;
        dispatch(setGlobalRegister({
            model: info.phone_model,
            size: size,
            color: color,
            telco: nowUse,
            telco_type: use?.id == nowUse?.telco_id ? '1' : '2',
            discount_type: discountType,
            plan: payment,
            buyType: Number(buyType),
            price: price,
            imDiscount: Number(info.phone_im_discount),
            discount1: Number(payment?.discount_price_1 ?? 0),
            discount2: Number((payment?.plan_price ?? 0)*0.25),
            cardDiscount: Number(cardType?.item?.dis_card_month_count ?? 0) * Number(cardType?.item?.dis_card_month_price ?? 0),
            card: cardType ?? undefined,
        }))
    },[info,size,color,price,use,nowUse,payment,discountType,discount,buyType,cardType])

    // 사이즈에 따른 기기값 변경
    useEffect(()=>{
        if(info === undefined) return;
        if(size === undefined || size?.idx === undefined){
            setPrice(info.phone_upgrade_price_1);
        }else{
            setPrice(info[`phone_upgrade_price_${Number(size?.idx)+1}`])
        }
    },[size,info])

    useEffect(()=>{
        if(id === undefined){
            navigate('/');
            return;
        }
        getDetail();
    },[id]);

    useEffect(()=>{
        if(id === undefined) return;
        if(nowUse?.telco_id === undefined) return;
        getPayment();
    },[id,nowUse?.telco_id]);

    const getDetail = async() =>{
        const res = await apiGetPhDetail('1',id);
        if(res.result && (res.data.length !== 0)){
            setInfo(res.data[0]);
            setData(res.data[0]);
        }
    }

    const getPayment = async() =>{
        const res = await apiGetDiscount(id,nowUse.telco_id);
        if(res.result){
            setPaymentList(res.data.plan_list);
            setPayment(res.data.plan_list[res.data.plan_list.length-1])
            setCardList(res.data.card_list);
            setCardType(undefined);
        }
    }

    const setData = (data:any) =>{
        setSize(data.phone_volume[0]);
        setColor(data.phone_colors[0]);
        setNowUse({...data.phone_support_telco[0],...TEL_LIST[data.phone_support_telco[0].telco_id]});
    }
    
    return (
        <LayoutPage>
            <MaxWidthWrap>
                <TitleWrap>
                    <BasicText fontSize={26} mFontSize={22} fontWeight={700}>{info?.phone_name ?? info?.phone_model}</BasicText>
                    <span>{info?.phone_network}</span>
                </TitleWrap>
                <Wrap>
                    <PartWrap>
                        <SelectPart 
                            info={info}
                            size={{size,setSize}}
                            color={{color,setColor}}
                            use={{use,setUse}}
                            nowUse={{get:nowUse,set:setNowUse}}
                            payment={{list:paymentList,get:payment,set:setPayment}}
                            discountType={{get:discountType,set:setDiscountType,setDiscount:setDiscount}}
                            buyType={{get:buyType,set:setBuyType}}
                            card={{list:cardList,get:cardType,set:setCardType}}
                        />
                    </PartWrap>
                    <ResultWrap>
                        <ResultPart id={info?.phone_id} phone_is_revervation={info?.phone_is_revervation} price={price} discountType={discountType} discount={discount} payment={payment} buyType={buyType} imDiscount={Number(info?.phone_im_discount ?? 0)} cardType={cardType} />
                    </ResultWrap>
                    <PartWrap>
                        <ContentPart info={info} />
                    </PartWrap>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const TitleWrap = styled(Row)`
    gap: 10px;
    & span{
        color: #000;
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #000;
        border-radius: 4px;
        padding: 2px;
    }
`;
const Wrap = styled(Row)`
    flex-wrap: wrap;
    align-items: start;
    gap: 30px;
    margin-top: 12px;
`;

const PartWrap = styled.div`
    width: 100%;
    max-width: 740px;
    &:nth-last-of-type(1){
        margin-top: 60px;
    }
    @media ${addTheme.media.l}{
        max-width: 100%;
    }
`;

const ResultWrap = styled.div`
    width: 100%;
    max-width: 480px;
    @media ${addTheme.media.l}{
        max-width: 100%;
    }
`;