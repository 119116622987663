import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LeftNavBar from '../../../common/part/LeftNavBar'
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdminWrap, BtnItem, Center, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { useNavigate, useParams } from 'react-router';
import { addTheme } from '../../../../style/addTheme';
import { ADMIN_POPUP_PAGE } from '../../../../data/tableTitle';
import { TableCell, TableRow } from '@mui/material';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { apiAdmGetBannerList, apiAdmSetDelBanner } from '../../../../api/adm/adm_api_banner';

const ListItem = ({data,itemClick,clickDel}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADMIN_POPUP_PAGE[0].align}>{data.banner_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_POPUP_PAGE[1].align}>{data.banner_title}</TableCell>
            <TableCell sx={{padding:'10px','& img':{maxWidth:'100%',height:'136px'}}} align={ADMIN_POPUP_PAGE[2].align}>
                <img src={data.banner_img_mo}></img>
            </TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_POPUP_PAGE[3].align}>{data.banner_link_url}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_POPUP_PAGE[4].align}>
                <Center style={{gap:'8px'}}>
                    <DetailBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={itemClick}>수정</DetailBtnItem>
                    <DelBtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={clickDel}>삭제</DelBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

export default function AdminBannerPage() {
    const {page} = useParams();
    const navigate = useNavigate();
    const adminInfo = useAdminInfo();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0) return;
        getList();
    },[page,adminInfo.admin_user_id])

    const setPage = (p:any) =>{
        navigate(`/adm/popup/${p}`);
    }

    const goUpdate = (id?:any) =>{
        navigate(`/adm/popupUpdate/${id ?? ''}`);
    }

    const clickDel = async(id:any) =>{
        if(window.confirm('팝업을 삭제하시겠습니까?')){
            const res = await apiAdmSetDelBanner(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
            if(res.result){
                alert('팝업을 삭제했습니다.');
                getList();
            }else{
                alert(res.error);
            }
        }
    }

    const getList = async() =>{
        const res = await apiAdmGetBannerList(adminInfo.admin_jwt_token,adminInfo.admin_user_id,page ?? 1, 15,'2');
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    return (
        <LeftNavBar idKey='9'>
            <Wrap>
                <AdminPageTotalPart title='전체 팝업' part='popup'>
                    <BtnItem bg='#ff7600' onClick={()=>goUpdate()}>팝업 등록</BtnItem>
                </AdminPageTotalPart>
                <AdminWrap>
                    <TitleWrap>
                        <BasicText fontSize={18} mFontSize={16} fontWeight={700}>팝업 관리</BasicText>
                    </TitleWrap>
                    <TableWrap>
                        <AdminTablePart bg={addTheme.main} color='#fff' title={ADMIN_POPUP_PAGE} minWidth={1000} page={page ?? 1} total={total} setPage={setPage}>
                            {list === undefined || list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <ListItem key={`inquiry-${index}`} data={data} itemClick={()=>goUpdate(data.banner_id)} clickDel={()=>clickDel(data.banner_id)} />
                                    )
                                })
                            }
                        </AdminTablePart>
                    </TableWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;

const DetailBtnItem = styled(BtnItem)``;
const DelBtnItem = styled(BtnItem)``;