import React from 'react'
import styled from 'styled-components'
import { ContentWrap, InputWrap, ItemWrap } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { valueChange } from '../../../../utils/format/string';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';

const STATUS:any = {
    '0': '대기중',
    '1': '상담완료',
}

const STATUS_LIST:any = [
    {val: '0', name: '대기중'},
    {val: '1', name: '상담완료'},
]

const USE_TEL:any = {
    '1': 'SKT',
    '2': 'KT',
    '3': 'LGU+',
    '4': '알뜰폰'
}

export default function AdmEasyOrderDetailInfoPart({info,setInfo}:any) {
    const infoChange = (name:string,val:any)=>{
        setInfo((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }

    return (
        <Wrap>
            <ContentWrap gap={15}>
                <ItemWrap>
                    <BasicText>이름</BasicText>
                    <InputWrap padding='13px 12px'>
                        {info.est_user_name}
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>핸드폰 번호</BasicText>
                    <InputWrap padding='13px 12px'>
                        {info.est_phone_number}
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>현재 통신사사</BasicText>
                    <InputWrap padding='13px 12px'>
                        {USE_TEL[info.est_using_telco_id]}
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>원하는 통신사</BasicText>
                    <InputWrap padding='13px 12px'>
                        {USE_TEL[info.est_want_telco_id]}
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>모델</BasicText>
                    <InputWrap padding='13px 12px'>
                        {info.est_want_model}
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>문의 상태</BasicText>
                    <InputWrap padding='0'>
                        <SelectItem value={info?.est_status ?? '0'} setValue={(val:any)=>infoChange('est_status',val)} none='no'>
                            {STATUS_LIST.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`status-${index}`} value={data.val}>{data.name}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    </InputWrap>
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    ) 
}

const Wrap = styled.div``;