import React from 'react'
import styled from 'styled-components';
import { RiKakaoTalkFill } from "react-icons/ri";
import { IoCall } from "react-icons/io5";
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useGlobalCompany } from '../../../utils/custom-hooks/common/useGlobalCompany';
import { useMediaQuery } from '@mui/material';
import BasicText from '../../basic/BasicText';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import { IoGift } from "react-icons/io5";

export default function QnaContactItem() {
    const theme = useTheme();
    const company = useGlobalCompany();
    const mediaQuery = useMediaQuery(addTheme.media.m);
    
    const kakaoClick = () =>{
        if(company.link['config_social_kakao_link'] === undefined) return;
        window.open(company.link['config_social_kakao_link']);
    }
    const phoneClick = () =>{
        if(company.link['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + company.link['config_company_call_number'];
    }
    return (
        <Wrap>
            {mediaQuery ?
                <>
                    <LogoWrap bg='#fae100' onClick={kakaoClick}>
                        <RiKakaoTalkFill color='#3c1e1e' size={32} />
                    </LogoWrap>
                    <LogoWrap bg='#d8e1f5' onClick={kakaoClick}>
                        <IoGift color='#000' size={28} />
                    </LogoWrap>
                    <LogoWrap bg={addTheme.main} onClick={phoneClick}>
                        <IoCall color='#fff' size={28} />
                    </LogoWrap>
                </> :
                <PcWrap>
                    <BasicText fontWeight={700} color={theme.black}>마이폰 상담하기</BasicText>
                    <PcBtnWrap>
                        <PcBtnItem bg={addTheme.main} color='#fff' onClick={kakaoClick}>
                            <RiKakaoTalkFill color='#fff' size={20} /> 실시간 카톡 상담
                        </PcBtnItem>
                        <PcBtnItem bg='#f2f4f9' color='#111' onClick={kakaoClick}>
                            <IoGift color='#000' size={20} /> 사은품 혜택 문의
                        </PcBtnItem>
                        <PcBtnLastItem onClick={phoneClick}>
                            <BasicText fontSize={14}>마이폰은 365일</BasicText>
                            <BasicText fontSize={14}>24시간 언제든 상담 OK</BasicText>
                            <BasicText fontSize={22} fontWeight={700}>{company?.link['config_company_call_number'] ?? '1833-7371'}</BasicText>
                            <PcBtnImgWrap>
                                <img src='/img/asset/icon-01.png'></img>
                            </PcBtnImgWrap>
                        </PcBtnLastItem>
                    </PcBtnWrap>
                </PcWrap> 
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    position: fixed;
    top: 150px;
    right: 20px;
    z-index: 12;
`;

const LogoWrap = styled(Center)<{bg:string}>`
    width: 50px;
    height: 50px;
    background-color: ${({bg}) => bg ?? '#fff'};
    margin-top: 10px;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;
    @media ${addTheme.media.sx}{
        width: 46px;
        height: 46px;
    }
`;

const PcWrap = styled.div`
    background-color: #fff;
    border-radius: 15px;
    width: 220px;
    padding: 15px;
    box-shadow: 3px 3px 8px rgba(0,0,0,0.25);
`;

const PcBtnWrap = styled(Row)`
    padding-top: 15px;
    flex-direction: column;
    gap: 8px;
`;

const PcBtnItem = styled(Center)<{bg:string,color?:string}>`
    width: 100%;
    height: 50px;
    background-color: ${({bg}) => bg ?? '#fff'};
    color: ${({color}) => color ?? '#000'};
    border-radius: 6px;
    font-size: 14px;
    gap: 10px;
    cursor: pointer;
`;

const PcBtnLastItem = styled(Center)`
    flex-direction: column;
    background-color: #f2f4f9;
    width: 100%;
    padding: 20px 10px 10px;
    cursor: pointer;
`;

const PcBtnImgWrap = styled.div`
    width: 100%;
    padding: 10px;
    &>img{
        width: 100%;
    }
`;