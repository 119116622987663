import { axiosConfigInstance } from "./config"

// 회사정보
export const apiGetCompany = async() =>{
    try {
        const res = await axiosConfigInstance('/config/info').post('',{
            method: 'getCompanyConfigInfo',
            params: {}
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 배너 리스트 1 - 메인배너 / 2 - 팝업
export const apiGetBannerList = async(page:any,count:any,part_type:'1'|'2') =>{
    try {
        const res = await axiosConfigInstance('/banner/info').post('',{
            method: 'getBannerList',
            params: {
                page,count,part_type
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 빠른 견적신청
export const apiSetEasyOrder = async(info:any) =>{
    try {
        const res = await axiosConfigInstance('/estimate/submit',).post('',{
            method: 'submitPhoneEstimate',
            params: {
                ...info,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}