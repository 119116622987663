import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { Center } from '../../../style/basic/commonStyle'

interface tableNoItemType{
    colSpan:number;
    txt?:string;
}

export default function TableNoItem({colSpan,txt}:tableNoItemType) {
    
    return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <Center>
                    {txt === undefined || txt === '' ?
                        '리스트가 없습니다.' :    
                        txt
                    }
                </Center>
            </TableCell>
        </TableRow>
    )
}
