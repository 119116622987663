import { BrowserRouter, Route, Routes} from 'react-router-dom';
import ScrollToTop from '../utils/custom-hooks/common/ScrollToTop';
import DetailPage from '../components/detail/page/DetailPage';
import HomePage from '../components/home/page/HomePage';
import AdminLoginPage from '../components/admin/login/page/AdminLoginPage';
import AdminHomePage from '../components/admin/home/page/AdminHomePage';
import NoPage from './NoPage';
import AdminOrderPage from '../components/admin/order/page/AdminOrderPage';
import AdminReviewPage from '../components/admin/review/page/AdminReviewPage';
import AdminOrderDetailPage from '../components/admin/order/page/AdminOrderDetailPage';
import AdminInquiryPage from '../components/admin/inquiry/page/AdminInquiryPage';
import QnaPage from '../components/Qna/page/QnaPage';
import InquiryDetailPage from '../components/Qna/page/InquiryDetailPage';
import InquiryCreatePage from '../components/Qna/page/InquiryCreatePage';
import ReviewPage from '../components/review/page/ReviewPage';
import RegisterPage from '../components/register/page/RegisterPage';
import RegisterDonePage from '../components/register/page/RegisterDonePage';
import MyPage from '../components/mypage/page/MyPage';
import ReviewCreatePage from '../components/review/page/ReviewCreatePage';
import AdminFaqPage from '../components/admin/faq/page/AdminFaqPage';
import AdminFaqUpdatePage from '../components/admin/faq/page/AdminFaqUpdatePage';
import AdminFaqCateUpdatePage from '../components/admin/faq/page/AdminFaqCateUpdatePage';
import AdminReviewUpdatePage from '../components/admin/review/page/AdminReviewUpdatePage';
import AdminInquiryUpdatePage from '../components/admin/inquiry/page/AdminInquiryUpdatePage';
import AdminInquiryAnswerPage from '../components/admin/inquiry/page/AdminInquiryAnswerPage';
import AdminLinkPage from '../components/admin/link/page/AdminLinkPage';
import AdminProductPage from '../components/admin/product/page/AdminProductPage';
import AdminProductDetailPage from '../components/admin/product/page/AdminProductDetailPage';
import { apiUser } from '../api/api_mypage';
import React,{ useEffect } from 'react';
import TermsInfoPage from '../components/terms/page/TermsInfoPage';
import TermsPage from '../components/terms/page/TermsPage';
import TermsShoppingPage from '../components/terms/page/TermsShoppingPage';
import PopupPart from '../components/common/part/PopupPart';
import AdminBannerPage from '../components/admin/banner/page/AdminBannerPage';
import AdminPopupPage from '../components/admin/popup/page/AdminPopupPage';
import AdminBannerUpdatePage from '../components/admin/banner/page/AdminBannerUpdatePage';
import AdminPopupUpdatePage from '../components/admin/popup/page/AdminPopupUpdatePage';
import AdminProductAddPage from '../components/admin/product/page/AdminProductAddPage';
import EasyOrderPage from '../components/easyOrder/page/EasyOrderPage';
import EasyOrderDonePage from '../components/easyOrder/page/EasyOrderDonePage';
import AdmEasyOrderListPage from '../components/admin/easyOrder/page/AdmEasyOrderListPage';
import AdmEasyOrderDetailPage from '../components/admin/easyOrder/page/AdmEasyOrderDetailPage';

export default function Router(){

    useEffect(()=>{
        user();
    },[])

    const user = async() =>{
        await apiUser();
    }

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            {/* <PopupPart /> */}
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/detail/:id?' element={<DetailPage />} />
                <Route path='/qna/:tab?/:page?' element={<QnaPage />} />
                <Route path='/inquiryDetail/:id' element={<InquiryDetailPage />} />
                <Route path='/inquiryCreate' element={<InquiryCreatePage />} />
                <Route path='/review/:page' element={<ReviewPage />} />
                <Route path='/reviewCreate/' element={<ReviewCreatePage />} />
                <Route path='/register/:id' element={<RegisterPage />} />
                <Route path='/registerDone' element={<RegisterDonePage />} />
                <Route path='/myPage' element={<MyPage />} />
                <Route path='/terms' element={<TermsPage />} />
                <Route path='/termsInfo' element={<TermsInfoPage />} />
                <Route path='/termsShopping' element={<TermsShoppingPage />} />
                <Route path='/easyOrder' element={<EasyOrderPage />} />
                <Route path='/easyOrderDone' element={<EasyOrderDonePage />} />
                <Route path='/adm'>
                    <Route path='' element={<AdminLoginPage />} />
                    <Route path='home' element={<AdminHomePage />} />
                    <Route path='easyOrder/:page?' element={<AdmEasyOrderListPage />} />
                    <Route path='easyOrderDetail/:id?' element={<AdmEasyOrderDetailPage />} />
                    <Route path='order/:page?' element={<AdminOrderPage />} />
                    <Route path='orderDetail/:id?' element={<AdminOrderDetailPage />} />
                    <Route path='review/:page?' element={<AdminReviewPage />} />
                    <Route path='reviewUpdate/:id?' element={<AdminReviewUpdatePage />} />
                    <Route path='inquiry/:page?' element={<AdminInquiryPage />} />
                    <Route path='inquiryUpdate' element={<AdminInquiryUpdatePage />} />
                    <Route path='inquiryAnswer/:id' element={<AdminInquiryAnswerPage />} />
                    <Route path='faq' element={<AdminFaqPage />} />
                    <Route path='faqUpdate/:id?' element={<AdminFaqUpdatePage />} />
                    <Route path='faqCateUpdate/:id?' element={<AdminFaqCateUpdatePage />} />
                    <Route path='product/:page?' element={<AdminProductPage />} />
                    <Route path='productDetail/:id' element={<AdminProductDetailPage />} />
                    <Route path='productAdd' element={<AdminProductAddPage />} />
                    <Route path='banner/:page' element={<AdminBannerPage />} />
                    <Route path='bannerUpdate/:id?' element={<AdminBannerUpdatePage />} />
                    <Route path='popup/:page' element={<AdminPopupPage />} />
                    <Route path='popupUpdate/:id?' element={<AdminPopupUpdatePage />} />
                    <Route path='link' element={<AdminLinkPage />} />
                </Route>
                <Route path='*' element={<NoPage />} />
            </Routes>
        </BrowserRouter>
    )
}