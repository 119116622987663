import React, { useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { Center, ContentWrap, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { valueChange } from '../../../utils/format/string';
import { USE_CARRIER } from '../../../data/data';
import { MenuItem } from '@mui/material';
import SelectItem from '../../common/item/SelectItem';
import { TEL_LIST_CATE } from '../../../data/payment';
import { apiSetEasyOrder } from '../../../api/api_home';
import { useNavigate } from 'react-router';
import { EASY_ORDER_TERMS } from '../data';

const INFO_INIT = {
    user_name: '',
    phone_number: '',
    using_telco_id: '1',
    want_telco_id: '1',
    phone_model: '',
}
export default function EasyOrderPage() {
    const navigate = useNavigate();
    const [info,setInfo] = useState(INFO_INIT);
    const [chk,setChk] = useState(false);
    const [terms,setTerms] = useState(false);

    const infoChange = (name:string,val:any) =>{
        setInfo((obj:any)=>{
            return {
                ...obj,
                [name]: val,
            }
        })
    }
    const orderSend = async(e:any) =>{
        e.preventDefault();
        if(chk){
            const res = await apiSetEasyOrder(info);
            if(res.result){
                alert('빠른 상담 예약을 했습니다.');
                navigate('/easyOrderDone');
            }
        }else{
            alert('약관에 동의해주세요.');
        }
    }
    return (
        <LayoutPage>
            <MaxWidthWrap>
                <Wrap>
                    <TitleWrap>
                        <div>
                            {/* <BasicText fontSize={38} mFontSize={24} fontWeight={700} align='center'>법적 최대 지원금 보장<br/>휴대폰 최저 가격 확인하기</BasicText> */}
                            <BasicText fontSize={38} mFontSize={24} fontWeight={700} align='center'>법적 최대 지원금 보장<br/> "나의 법적최대지원금" 확인하기</BasicText>
                            <BasicText fontSize={28} mFontSize={18} align='center' marginTop={3}>원하시는 휴대폰 상품을 선택해주세요.</BasicText>
                        </div>
                    </TitleWrap>
                    <FormWrap>
                        <form onSubmit={orderSend}>
                            <ContentWrap gap={16}>
                                <ItemWrap>
                                    <BasicText spColor='red'>이름 <span>*</span></BasicText>
                                    <ItemInputWrap>
                                        <input maxLength={20} value={info.user_name} onChange={(e:any) => infoChange('user_name',e.target.value)} required placeholder='이름을 입력해주세요.'></input>
                                    </ItemInputWrap>
                                </ItemWrap>
                                <ItemWrap>
                                    <BasicText spColor='red'>핸드폰 번호 <span>*</span></BasicText>
                                    <ItemInputWrap>
                                        <input maxLength={16} value={info.phone_number} onChange={(e:any) => infoChange('phone_number',valueChange(e))} required placeholder='핸드폰번호를 입력해주세요.'></input>
                                    </ItemInputWrap>
                                </ItemWrap>
                                <ItemWrap>
                                    <BasicText spColor='red'>현재 통신사 <span>*</span></BasicText>
                                    <ItemInputWrap style={{padding:0}}>
                                        <SelectItem value={info.using_telco_id} setValue={(val:any)=>infoChange('using_telco_id',val)} none={'none'} width='100%'>
                                            {USE_CARRIER === undefined || USE_CARRIER.length === 0 ?
                                                <MenuItem value={''}></MenuItem> :
                                                USE_CARRIER.map((data:any,index:any)=>{
                                                    return(
                                                        <MenuItem key={`cate-${index}`} value={data.id}>{data.title}</MenuItem>
                                                    )
                                                })
                                            }
                                        </SelectItem>
                                    </ItemInputWrap>
                                </ItemWrap>
                                <ItemWrap>
                                    <BasicText spColor='red'>사용 희망 통신사 <span>*</span></BasicText>
                                    <ItemInputWrap style={{padding:0}}>
                                        <SelectItem value={info.want_telco_id} setValue={(val:any)=>infoChange('want_telco_id',val)} none={'none'} width='100%'>
                                            {TEL_LIST_CATE === undefined || TEL_LIST_CATE.length === 0 ?
                                                <MenuItem value={''}></MenuItem> :
                                                TEL_LIST_CATE.map((data:any,index:any)=>{
                                                    return(
                                                        <MenuItem key={`cate-${index}`} value={data.telco_id}>{data.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </SelectItem>
                                    </ItemInputWrap>
                                </ItemWrap>
                                <ItemWrap>
                                    <BasicText spColor='red'>원하시는 기종 <span>*</span></BasicText>
                                    <ItemInputWrap>
                                        <input maxLength={30} value={info.phone_model} onChange={(e:any) => infoChange('phone_model',e.target.value)} required placeholder='원하시는 기종을 입력해주세요(ex. 갤럭시S25 256기가)'></input>
                                    </ItemInputWrap>
                                </ItemWrap>
                                <ItemWrap>
                                    <BasicText spColor='red'>개인정보 수집 및 이용 <span>*</span></BasicText>
                                    <Row style={{gap:'10px'}}>
                                        <label>
                                            <input type='checkbox' onChange={(e:any)=>setChk(e.target.checked)} required></input> 전체 약관에 동의합니다.
                                        </label>
                                         <BasicText fontSize={15} color='#9b9b9b' deco='underline' style={{cursor:'pointer'}} onClick={()=>setTerms(!terms)}>자세히 보기</BasicText>
                                    </Row>
                                </ItemWrap>
                                {terms && <TermsWrap>{EASY_ORDER_TERMS}</TermsWrap>}
                            </ContentWrap>
                            <BtnWrap type='submit'>최대지원금 대상자 확인하기</BtnWrap>
                        </form>
                    </FormWrap>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(Center)`
    margin-top: 100px;
`;
const FormWrap = styled.div`
    width: 100%;
    max-width: 650px;
    margin: 60px auto 0;
`;

const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 185px;
        flex-shrink: 0;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    & input{
        width: 100%;
    }
    & textarea{
        width: 100%;
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.main};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin-top: 50px;
`;
const TermsWrap = styled.div`
    line-height: 1.2;
    white-space: pre-wrap;
`;