import { axiosConfigInstance } from "../config";

// 관리자 링크 가져오기
export const apiAdmGetLink = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/config',token).post('',{
            method: 'getAdminConfigInfo',
            params:{
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 링크 수정
export const apiAdmSetLink = async(token:string,admin_id:any,config_company_name:any,config_company_repre_name:any,config_business_number:any,config_company_address:any,config_private_info_name:any,config_private_info_email:any,config_company_email:any,config_partner_company:any,config_online_prev_approve_code:any,config_company_call_number:any,config_social_kakao_link:any,config_review_link:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/config',token).post('',{
            method: 'adminModifyConfigInfo',
            params:{
                admin_id,config_company_name,config_company_repre_name,config_business_number,config_company_address,config_private_info_name,config_private_info_email,config_company_email,config_partner_company,config_online_prev_approve_code,config_company_call_number,config_social_kakao_link,config_review_link
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}