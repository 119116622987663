import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Center, MaxWidthWrap, Row, SectionWrap } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { momentFormat } from '../../../utils/format/time';
import { CATE, NAME, PHONE_LIST } from '../../../api/listData';
import { useMediaQuery } from '@mui/material';
import BasicText from '../../basic/BasicText';

const Item = ({data}:any) =>{
    const mediaQuery = useMediaQuery(addTheme.media.s);
    return (
        <ListItemWrap>
            <ListItem w={15}>{momentFormat(mediaQuery ? 'MM-DD' : 'YYYY-MM-DD',data.time)}</ListItem>
            <ListItem w={mediaQuery ? 45 : 50}>
                {data.state === '접수 완료' &&
                    <NewItem>NEW</NewItem> 
                }
                {data.productName}
            </ListItem>
            <ListItem w={20} center='center'>{data.name}</ListItem>
            <ListItem w={mediaQuery ? 20 : 15} center='center'>
                <BtnItem stateOn={data.state === '상담 완료'}>{data.state}</BtnItem>
            </ListItem>
        </ListItemWrap>
    )
}

export default function PhoneUserListPart() {
    const mediaQuery = useMediaQuery(addTheme.media.s);
    const [list,setList] = useState<any>([]);
    const [listUpdate,setListUpdate] = useState(false);
    
    useEffect(()=>{
        getList();
    },[]);

    useEffect(()=>{
        if(list.length === 0) return
        const timer = setTimeout(() => {
            if(list.length > 8){
                setList((arr:any)=>arr.slice(0, -1))
                setListUpdate(true);
            }else{
                setListUpdate(false);
                addList();
            }
        }, 1000);
        return () => clearTimeout(timer);
    },[list.length])

    // 리스트 생성
    const getList = () =>{
        if(list.length === 0){
            const time = new Date();
            const yesterDay = time.setDate(time.getDate() - 1);
            const arr = Array.from({length: 8},(_,index)=>{
                const {name,productName} = ranData();
                return {name: name, time: yesterDay,productName, state: '상담 완료'}
            })
            setList(arr);
        }
    }

    // 랜덤으로 데이터 생성
    const ranData = () =>{
        const nameRanNum = Math.floor(Math.random() * NAME.length);
        const name = NAME[nameRanNum];

        const cateRanNum = Math.floor(Math.random() * CATE.length);
        const cate = CATE[cateRanNum];
        const productRanNum = Math.floor(Math.random() * PHONE_LIST.length);
        const productName = PHONE_LIST[productRanNum];
        return {name,productName}
    }

    // 리스트 추가
    const addList = () =>{
        const nowTime = new Date().getTime();
        const {name,productName} = ranData();
        const newItem = {name: name, time: nowTime,productName, state: '접수 완료'}
        setList((arr:any)=>[newItem,...arr]);
    }
    
    return (
        <SectionWrap bg={'#F2F6FF'}>
            <MaxWidthWrap>
                <Wrap>
                    {/* <TitlePart desc='고객님의 휴대폰을 나의폰처럼럼' /> */}
                    <BasicText fontSize={31} mFontSize={22} fontWeight={700} spColor={addTheme.main} align='center'>
                        고객님의 휴대폰을 나의폰처럼{mediaQuery && <br/>} <span>"마이폰"</span> 실시간 접수현황
                    </BasicText>
                    <ContentWrap>
                        <TitleWrap>
                            <TitleItemWrap w={15}>날짜</TitleItemWrap>
                            <TitleItemWrap w={mediaQuery ? 45 : 50}>신청상품</TitleItemWrap>
                            <TitleItemWrap w={20} center='center'>이름</TitleItemWrap>
                            <TitleItemWrap w={mediaQuery ? 20 : 15} center='center'>신청 현황</TitleItemWrap>
                        </TitleWrap>
                        <ListWrap>
                            <InnerWrap listUpdate={listUpdate}>
                                {list.length === 0 ?
                                    <></> :
                                    list.map((data:any,index:any)=>{
                                        return(
                                            <Item key={`user-${index}`} data={data} />
                                        )
                                    })
                                }
                            </InnerWrap>
                        </ListWrap>
                    </ContentWrap>
                </Wrap>
            </MaxWidthWrap>
        </SectionWrap>
    )
}

const Wrap = styled.div`
    & span{
        font-size: 36px;
    }
    @media ${addTheme.media.m}{
        font-size: 28px;
    }
`;
const ContentWrap = styled.div`
    margin-top: 70px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 30px;
    @media ${addTheme.media.s}{
        padding: 15px;
        border-radius: 15px;
    }
`;

const TitleWrap = styled(Row)`
    border-bottom: 1px solid #333;
    padding: 10px 0;
`;

const TitleItemWrap = styled.div<{w?:number,center?:string}>`
    width: ${({w}) => w ?? 25}%;
    display: flex;
    align-items: center;
    justify-content: ${({center}) => center ?? 'start' };
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    @media ${addTheme.media.s}{
        font-size: 16px;
    }
`;

const ListWrap = styled.div`
    height: 360px;
    overflow: hidden;
    transition: 0.3s;
    position: relative;
`;

const upDown = keyframes`
    from {
        bottom: 0;
    }
    to {
        bottom: -60px;
    }
`;
const InnerWrap = styled.div<{listUpdate:boolean}>`
    position: absolute;
    bottom: -60px;
    left: 0;
    width: 100%;
    animation: ${({listUpdate}) => listUpdate ? upDown : 'none'} 0.6s ease-in-out;
`;

const ListItemWrap = styled(Row)`
    height: 60px;
`;

const ListItem = styled.div<{w?:number,center?:string}>`
    width: ${({w}) => w ?? 25}%;
    display: flex;
    align-items: center;
    justify-content: ${({center}) => center ?? 'start' };
    font-size: 18px;
    gap: 8px;
    @media ${addTheme.media.s}{
        font-size: 14px;
    }
`;

const BtnItem = styled.div<{stateOn?:boolean}>`
    padding: 12px;
    background-color: ${({stateOn}) => stateOn ? addTheme.main : '#e9effc'};
    color: ${({stateOn}) => stateOn ? '#fff' : addTheme.main};
    border-radius: 5px;
    text-align: center;
`;

const NewItem = styled(Center)`
    width: 30px;
    height: 30px;
    background-color: ${addTheme.main};
    color: #fff;
    border-radius: 30px;
    font-size: 10px;
    margin-left: 5px;
    flex-shrink: 0;
    @media ${addTheme.media.s}{
        width: 24px;
        height: 24px;
        font-size: 7px;
    }
`;