import React from 'react'
import styled from 'styled-components'
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { numberPrice, numberWithComma } from '../../../utils/format/number';

export default function ResultPriceItem({price,discountType,discount,cardDiscount,imDiscount,result}:any) {
    return (
        <Wrap>
            <ItemWrap>
                <BasicText color='#000' fontSize={19} mFontSize={16}>출고가</BasicText>
                <BasicText color='#000' fontSize={19} mFontSize={16} fontWeight={700}>{numberWithComma(price ?? '0')}원</BasicText>
            </ItemWrap>
            {discountType === '1' &&
                <ItemWrap>
                    <BasicText color='#000' fontSize={19} mFontSize={16}>공시지원금</BasicText>
                    <BasicText color='#000' fontSize={19} mFontSize={16} fontWeight={700}>- {numberWithComma(discount)}원</BasicText>
                </ItemWrap>
            }
            {!(imDiscount === undefined || imDiscount == '0') &&
                <ItemWrap>
                    <BasicText color='#000' fontSize={19} mFontSize={16}>마이폰 할인</BasicText>
                    <BasicText color='#000' fontSize={19} mFontSize={16} fontWeight={700}>- {numberWithComma(imDiscount ?? '0')}원</BasicText>
                </ItemWrap>
            }
            {!(cardDiscount === undefined || cardDiscount == '0') &&
                <ItemWrap>
                    <BasicText color='#000' fontSize={19} mFontSize={16}>제휴 카드 할인</BasicText>
                    <BasicText color='#000' fontSize={19} mFontSize={16} fontWeight={700}>- {numberWithComma(cardDiscount ?? '0')}원</BasicText>
                </ItemWrap>
            }
            <ResultItemWrap>
                <BasicText color='#fff' fontSize={20} mFontSize={17}>할부원금</BasicText>
                <BasicText color='#fff' fontSize={20} mFontSize={17} fontWeight={700}>{numberWithComma(result)}원</BasicText>
            </ResultItemWrap>
            {discountType === '2' &&
                <ItemWrap>
                    <BasicText color='#000' fontSize={19} mFontSize={16}>선택약정할인</BasicText>
                    <BasicText color='#000' fontSize={19} mFontSize={16} fontWeight={700}>- {numberWithComma(discount)}원</BasicText>
                </ItemWrap>
            }
        </Wrap>
    )
}

const Wrap = styled(Row)`
    border: 1px solid #d2d2d2;
    padding: 15px;
    background-color: #fff;
    margin-top: 20px;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
`;
const ItemWrap = styled(RowBetween)``;
const ResultItemWrap = styled(RowBetween)`
    padding: 10px 20px;
    background-color: ${addTheme.main};;
    &>p:nth-last-of-type(1){
        position: relative;
    }
    &>p:nth-last-of-type(1)::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 12px solid rgba(255, 255, 255, 0.1);
    }
`;